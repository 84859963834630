import { FC } from 'react';
import {
  PublicGetLevelPageResponse,
  SearchResult,
} from '@les-sherpas/sherpas-toolbox';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import server from 'config';
import { GetStaticPaths, GetStaticPathsResult } from 'next';
import Head from 'next/head';

import getStructuredData from '@/components/LandingMozza/LandingSeo/LandingLevel/getStructuredData';
import LandingLevel from '@/components/LandingMozza/LandingSeo/LandingLevel/LandingLevel';
import { apiGetSubjects } from '@/shared/API/apiGetSubjects';
import { useCanonicalUrl } from '@/shared/Hooks/useCanonicalUrl';
import { defaultQueryFn } from '@/utils/createQueryClient';
import { getListTeachers } from '@/utils/Seo.utils';

type Props = PublicGetLevelPageResponse & {
  announces: SearchResult[];
};

const LandingSeoLevelPage: FC<Props> = ({
  template,
  announces,
  level,
  links,
}) => {
  const canonicalUrl = useCanonicalUrl();

  return (
    <>
      <Head>
        <title>{template.meta.title}</title>
        <meta name="description" content={template.meta.description} />
        <link rel="canonical" href={canonicalUrl} />
        <script
          key="structured-data"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: getStructuredData({
              canonicalUrl,
              level: level.name,
              meta: {
                title: template.meta.title,
                description: template.meta.description,
              },
            }),
          }}
        />
      </Head>
      <LandingLevel
        template={template}
        announces={announces}
        level={level}
        links={links}
      />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = (): GetStaticPathsResult => ({
  paths: [{ params: { level: 'bts' } }],
  fallback: 'blocking',
});

export async function getStaticProps({ params }) {
  const { level } = params;
  const queryClient = new QueryClient();

  try {
    const { data } = await axios.get<PublicGetLevelPageResponse>(
      `${server}seo-student-pages/public/get-level-page/${level}`,
      {
        validateStatus: (status) => status === 200,
      }
    );
    const [announces] = await Promise.all([
      getListTeachers('', data.level.educationalStage, null, 8),
      queryClient.prefetchQuery(['subject'], apiGetSubjects),
      queryClient.prefetchQuery(
        ['tv-campaign/public/get-active-campaign'],
        defaultQueryFn
      ),
    ]);

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
        announces,
        ...data,
        isTvBannerDisplayed: false,
      } satisfies Props & {
        isTvBannerDisplayed: boolean;
        dehydratedState: DehydratedState;
      },
      revalidate: 60 * 60 * 24,
    };
  } catch (e) {
    return {
      notFound: true,
    };
  }
}
export default LandingSeoLevelPage;
